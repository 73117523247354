import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgbModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { CookieService } from 'ngx-cookie-service';
import { JwtModule } from "@auth0/angular-jwt";
import { ConfiguracionService } from './servicios/configuracion.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    AppRoutingModule,
    HttpClientModule, 
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    NgbModule,
    NgbAlertModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: ConfiguracionService.getBearer,
        allowedDomains: ["192.168.100.132:82"],
        disallowedRoutes: ["//authenticate", "//welcome"],
        authScheme: (request) => {
          if (request.url.includes("welcome") || request.url.includes("authenticate")) {
            return "";
          }
          return "Bearer ";
        },
      },
    })
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
