<nav class="main-navbar navbar navbar-expand-lg" style="padding-left: 20px;" [ngClass]="{'not-home': !router.isActive('/', true), 'expanded': !navbarCollapsed}" >
  <a class="navbar-brand" [routerLink]="['/']" (click)="navbarCollapsed = true">
    <img class="brand-logo" alt="ANGULAR STORE" title="ANGULAR STORE" src="/assets/imgs/NEXXUS TECHNOLOGY_mini.png">
  </a>
  <button (click)="navbarCollapsed = !navbarCollapsed" class="navbar-toggler" type="button" [attr.aria-expanded]="!navbarCollapsed" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon">
      <span *ngIf="navbarCollapsed" class="fa fa-bars"></span>
      <span *ngIf="!navbarCollapsed" class="fa fa-times"></span>
    </span>
  </button>

  <div class="navbar-items-wrapper navbar-collapse collapse" role="navigation" [collapse]="navbarCollapsed">
    <ul class="navbar-items-list navbar-nav ms-auto " >
      <li class="nav-search-bar-item d-lg-none">
        <app-search-bar class="store-search-bar"></app-search-bar>
      </li>
      <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="navbarCollapsed = true">
        <a class="nav-link" [routerLink]="['/']">
          Home
        </a>
      </li>
      <!--<li class="nav-item" routerLinkActive="active" (click)="navbarCollapsed = true">
        <a class="nav-link" [routerLink]="['/ganadores']">
          Ganadores
        </a>
      </li>-->
      <li class="nav-item" routerLinkActive="active" (click)="navbarCollapsed = true">
        <a class="nav-link" [routerLink]="['/sfel']">
          SFel
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active" (click)="navbarCollapsed = true">
        <a class="nav-link" [routerLink]="['/snak-center']">
          SnakCenter
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active" (click)="navbarCollapsed = true">
        <a class="nav-link" [routerLink]="['/services']">
          Servicios
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active" (click)="navbarCollapsed = true">
        <a class="nav-link" [routerLink]="['/contact']">
          Contactanos
        </a>
      </li>
      <!--<li class="nav-divider d-lg-none">
        <hr class="divider-line">
      </li>
      <li class="nav-item user-action-item" *ngIf="(loggedInObservable | async) === false" routerLinkActive="active" (click)="navbarCollapsed = true">
        <a class="nav-link" [routerLink]="['/create-account']">
          Dinámica
        </a>
      </li>
      <li class="nav-item user-action-item" *ngIf="(loggedInObservable | async) === false" routerLinkActive="active" (click)="navbarCollapsed = true">
        <a class="nav-link" [routerLink]="['/login']">
          Login
        </a>
      </li>
      <li class="nav-item user-action-item" *ngIf="(loggedInObservable | async)" routerLinkActive="active" (click)="navbarCollapsed = true">
        <a class="nav-link" [routerLink]="['/user']">
          Account
        </a>
      </li>
      <li class="nav-item user-action-item" *ngIf="(loggedInObservable | async)" (click)="navbarCollapsed = true">
        <a class="nav-link" (click)="logout()">
          Logout
        </a>
      </li>-->
    </ul>
  </div>
</nav>
