import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {

  public isSetTime = false;
  
  public static bearer = localStorage.getItem("epbr");
  public static cookieSys = "epsort";
  public static ip = "";
  
  
  constructor(
    public helper: JwtHelperService,
    private router: Router,
    private cookieService: CookieService) { } 

  public static getBearer(){
    return ConfiguracionService.bearer;
  }

  
  public sessionEnd(){
    this.cookieService.delete(ConfiguracionService.cookieSys);
    ConfiguracionService.bearer = null;

    window.localStorage.removeItem('user');
    window.localStorage.removeItem('epbr');
    window.localStorage.removeItem('xp');

    this.router.navigate(['/']);
  }

  
  getUser(){
    return this.getClaveToken("nombres")+" "+this.getClaveToken("apellidos");
  }

  getClaveToken(key){
    if(localStorage.getItem("epbr")!=null){
      var user = this.helper.decodeToken(localStorage.getItem("epbr"));
      return user[key];
    }else{
      return null;
    }
  }

}
