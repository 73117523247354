import { Injectable } from '@angular/core';
import decode from 'jwt-decode';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfiguracionService } from './configuracion.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isTimer = false;

  constructor(public helper: JwtHelperService,
    private router: Router,
    private cookieService: CookieService) { }

  public isAuthenticated(): boolean {
    var valid = !this.helper.isTokenExpired(ConfiguracionService.bearer);
    if(valid){
      this.setTimeOut();
    }
    return valid;
  }

  
  public setTimeOut(){
    if(!this.isTimer){
      const conversor = 0.0000115741;
      var user = this.helper.decodeToken(localStorage.getItem("epbr"));
      var exp = user["exp"];
      var actual = Math.round((new Date()).getTime() / 1000);
      var total = exp-actual;
      var timer = total*1000;

      var calc = (total*conversor);
      
      this.cookieService.set( ConfiguracionService.cookieSys, actual+'', {expires: calc, sameSite: 'Lax'});
      
      setTimeout(() => {
        this.sessionEnd();
      }, timer);

      this.isTimer = true;
    }
  }

  
  public sessionEnd(){
    this.cookieService.delete(ConfiguracionService.cookieSys);
    ConfiguracionService.bearer = null;

    window.localStorage.removeItem('epbr');

    this.router.navigate(['/']);
  }

  public decode(){
    console.log( this.helper.decodeToken(ConfiguracionService.bearer))
  }

  public expired(){
    console.log(this.helper.isTokenExpired(ConfiguracionService.bearer))
  }

  public dexpired(){
    console.log(this.helper.getTokenExpirationDate(ConfiguracionService.bearer))
  }

  public role(){
    const tokenPayload = decode(ConfiguracionService.bearer);
    console.log(tokenPayload)
  }
}
