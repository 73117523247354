import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Error404PageComponent } from './core';
import { AuthGuardService as AuthGuard } from './servicios/auth-guard.service';
import { AuthGuardNoneService as AuthGuardNone } from './servicios/auth-guard-none.service';

const routes: Routes = [
  // STORE
  {
    path: '',
    loadChildren: () => import('./store/home/store-home.module').then(m => m.StoreHomeModule),
    canActivate: [AuthGuardNone]
  },
  /*{
    path: 'products',
    loadChildren: () => import('./store/listing/store-listing.module').then(m => m.StoreListingModule)
  },
  {
    path: 'product/:slug',
    loadChildren: () => import('./store/product/store-product.module').then(m => m.StoreProductModule)
  },
  // AUTH
  {
    path: 'login',
    loadChildren: () => import('./auth/signin/auth-signin.module').then(m => m.AuthSigninModule)
  },
  {
    path: 'create-account',
    loadChildren:  () => import('./auth/signup/auth-signup.module').then(m => m.AuthSignupModule)
  },
  {
    path: 'recover-password',
    loadChildren: () => import('./auth/recover-password/auth-recover-password.module').then(m => m.AuthRecoverPasswordModule)
  },
  // USER
  {
    path: 'user',
    loadChildren:  () => import('./user/account/user-account.module').then(m => m.UserAccountModule)
  },
  // MISC
  {
    path: 'terminos-condiciones',
    loadChildren:  () => import('./misc/term/misc-term.module').then(m => m.MiscTermModule)
  },
  {
    path: 'ganadores',
    loadChildren:  () => import('./misc/ganadores/misc-ganadores.module').then(m => m.MiscGanadoresModule)
  },
  {
    path: 'dinamica',
    loadChildren:  () => import('./misc/dinamica/misc-dinamica.module').then(m => m.MiscDinamicaModule)
  },
  {
    path: 'account',
    loadChildren:  () => import('./store/cuenta/cuenta.module').then(m => m.CuentaModule),
    canActivate: [AuthGuard]
  },*/
  {
    path: 'privacy-policy',
    loadChildren:  () => import('./misc/about/misc-about.module').then(m => m.MiscAboutModule)
  },
  {
    path: 'terms-conditions',
    loadChildren:  () => import('./misc/term/misc-term.module').then(m => m.MiscTermModule)
  },
  {
    path: 'services',
    loadChildren:  () => import('./pages/servicios/servicios.module').then(m => m.ServiciosModule)
  },
  {
    path: 'contact',
    loadChildren:  () => import('./pages/contactanos/contactanos.module').then(m => m.ContactanosModule)
  },
  {
    path: 'sfel',
    loadChildren:  () => import('./pages/sfel/sfel.module').then(m => m.SfelModule)
  },
  {
    path: 'snak-center',
    loadChildren:  () => import('./pages/snak-center/snak-center.module').then(m => m.SnakCenterModule)
  },
  {
    path: '404',
    component: Error404PageComponent
  },
  {
    path: '**',
    component: Error404PageComponent
  }
];

@NgModule({
  // Only call RouterModule.forRoot() in the root AppRoutingModule (or the AppModule if that's where you register
  // top level application routes). In any other module, you must call the RouterModule.forChild method to register additional routes.
  imports: [
    RouterModule.forRoot(routes, {
      // If you want to preload all lazy routes when the app loads, uncomment the following line
      // preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
      // This value is required for server-side rendering to work.
      initialNavigation: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
